import React, { useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import './AccountSettings.css'

const ChangePassword = ({ user }) => {

  const [userdata, setuserdata] = React.useState({})

  const editpassword = () => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/B2CCustomerRegister/EditProfilePassword',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userdata),
      })
      .then(res => res.json())
      .then(data => {
        if (data.Code === 200) {
          // window.location.reload()
          toast.success('Password Updated Successfully')
          updateuserdata()
        }
        else {
          toast.error('Something Went Wrong')
        }
      })
      .catch(err => {
        toast.error('Something Went Wrong')
      })
  }

  const updateuserdata = () => {
    let user = localStorage.getItem('token')
    user = JSON.parse(user)
    fetch(process.env.REACT_APP_BACKEND_URL + '/B2CCustomerRegister/Getbycode?OrganizationId=' + process.env.REACT_APP_BACKEND_ORGANIZATION + '&B2CCustomerId=' + user[0].B2CCustomerId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
   
  }

  useEffect(() => {
    setuserdata(user[0])
  }, [user])


  return (
    <div className='accountsettings'>
      <h1 className='mainhead2'>Change Password</h1>
      <div className='form'>
        <div className='form-group'>
          <label htmlFor='name'>Current Password<span>*</span></label>
          <input type='text' name='name' id='name'
            value={userdata?.Password}
            onChange={(e) => setuserdata({ ...userdata, Password: e.target.value })}
          />
        </div>

        <div className='form-group'>
          <label htmlFor='email'>Email <span>*</span></label>
          <input style={{
            backgroundColor: "#f0f0f0",
            color: "#888",
            border: "1px solid #ccc",
            cursor: "not-allowed"
          }} type='email' name='email' id='email'
            value={userdata?.EmailId}
            //onChange={(e) => setuserdata({...userdata, EmailId: e.target.value})}
            readOnly
          />
        </div>

      </div>

      <button
        onClick={editpassword}
      >Save Changes</button>
    </div>
  )
}

export default ChangePassword